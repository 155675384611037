import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {UpdateMilkRoomSchema} from './UpdateMilkRoom';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    canDelete: t.boolean,
});

export const MilkRoomSchema = t.exact(t.intersection([
    UpdateMilkRoomSchema.type,
    optionalPart,
    requiredPart,
]));

export interface MilkRoom extends t.TypeOf<typeof MilkRoomSchema> {}
