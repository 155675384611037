import React, {Component, ReactNode} from 'react';

import {Components as Buttons} from 'buttons';
import {MilkRoomsTableMode} from './index';

interface Props {
    id: number;
    mode: MilkRoomsTableMode;
    supplierId?: number;
    canDelete: boolean;

    handleDelete?(id: number): void;
}

class RowActions extends Component<Props> {
    render(): ReactNode {
        const {
            handleDelete,
            id,
            mode,
            supplierId,
            canDelete,
        } = this.props;
        const detailUrl = `/suppliers/${supplierId}/milk-room/${id}`;
        return (
            <div>
                {mode === 'EDIT' && <Buttons.RowActionButton
                    type="UPDATE"
                    to={detailUrl}
                />}
                {handleDelete && mode !== 'VIEW' && <Buttons.RowActionButton
                    type="DELETE"
                    onClick={() => handleDelete(id)}
                    disabled={!canDelete}
                />}
            </div>
        );
    }
}

export default RowActions;
