import {flow} from 'lodash/fp';
import React, {Component, ComponentClass, ReactNode} from 'react';
import {connect} from 'react-redux';
import {match as Match} from 'react-router';
import {Dispatch} from 'redux';
import {Opt, opt} from 'ts-opt';

import {StoreState} from 'app/types/StoreState';
import {Components as Buttons} from 'buttons';
import {Components as Layout} from 'layout';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {SupplierDetails} from 'types/model/suppliers/SupplierDetails';
import withUser from 'user/components/withUser';
import {formValuesF, isFieldValidUnsafe} from 'utils/formHelpers';
import {
    deleteMilkRoom,
    loadSupplier,
    loadSuppliersMilkRooms,
    updateSupplier,
    fillUpdateSupplierFormFromAres,
} from '../actions';
import MilkRoomsTable, {DeleteMilkRoomFn} from '../components/MilkRoomsTable';
import UpdateSupplierForm from '../components/UpdateSupplierForm';
import {show as showConfirmDialog} from 'confirmDialog/actions';
import {UpdateSupplierFormValues} from '../types/UpdateSupplierFormValues';

interface OuterProps {}

interface InnerProps {
    supplier: SupplierDetails | null;
    match: Match<{id: string}>;
    handleDeleteMilkRoom: DeleteMilkRoomFn;
    suppliersMilkRooms: Opt<MilkRoom[]>;
    formValue: Opt<UpdateSupplierFormValues>;
    isIcoValid: boolean;
    isAresLoading: boolean;

    handleLoadSupplier(id: number): void;

    handleLoadMilkRooms(id: number): void;

    handleUpdateSupplier(id: number): void;

    fillFormFromAres(ico: string): void;
}

type Props = InnerProps & OuterProps;

class Detail extends Component<Props> {
    private get supplierId(): number {
        const {match} = this.props;
        return Number(match.params.id);
    }

    componentDidMount(): void {
        const {
            handleLoadSupplier,
            handleLoadMilkRooms,
        } = this.props;
        const id = this.supplierId;
        handleLoadSupplier(id);
        handleLoadMilkRooms(id);
    }

    render(): ReactNode {
        const {
            supplier,
            handleUpdateSupplier,
            handleDeleteMilkRoom,
            suppliersMilkRooms,
            formValue,
            fillFormFromAres,
            isIcoValid,
            isAresLoading,
        } = this.props;

        const handleSubmit = () => {
            handleUpdateSupplier(this.supplierId);
        };

        const ico = formValue.map(x => x.invoiceDetails.ico).orUndef();
        const hasNoIco = formValue.map(x => x.hasNoIco).orFalse();
        const onIcoSearch = () => {
            if (ico && isIcoValid) {
                fillFormFromAres(ico);
            }
        };

        return (
            <Layout.ItemPage
                title={`Dodavatel ${supplier?.name}`}
                backLabel="Dodavatelé"
            >
                <UpdateSupplierForm
                    onSubmit={handleSubmit}
                    hasNoIco={hasNoIco}
                    onIcoSearch={onIcoSearch}
                    isAresLoading={isAresLoading}
                />

                <h3 className="d-flex justify-content-between mt-4">
                    Seznam mléčnic
                    <Buttons.RightIconButton
                        label="Nová"
                        to={`/suppliers/${this.supplierId}/new-milk-room`}
                        icon="ADD"
                    />
                </h3>

                <Layout.LoaderWrapper showLoader={suppliersMilkRooms.isEmpty}>
                    {suppliersMilkRooms.map(smr =>
                        <MilkRoomsTable
                            milkRooms={smr}
                            handleDelete={handleDeleteMilkRoom}
                            mode="EDIT"
                            supplierId={this.supplierId}
                        />,
                    ).orNull()}
                </Layout.LoaderWrapper>
            </Layout.ItemPage>
        );
    }
}

const mapStateToProps = (state: StoreState): Partial<Props> => ({
    supplier: state.supplier.current,
    suppliersMilkRooms: opt(state.supplier.suppliersMilkRooms),
    formValue: formValuesF('updateSupplier')(state),
    isIcoValid: isFieldValidUnsafe('updateSupplier', 'invoiceDetails.ico')(state),
    isAresLoading: state.supplier.isAresLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
    handleLoadSupplier: (id: number) => dispatch(loadSupplier(id)),
    handleLoadMilkRooms: (id: number) => dispatch(loadSuppliersMilkRooms(id)),
    handleUpdateSupplier: (id: number) => dispatch(updateSupplier(id)),
    handleDeleteMilkRoom: (supplierId: number | null, milkRoomId: number, isLast: boolean) => {
        const lastWarning =
            'Smazáním poslední mléčnice dojde k automatickému smazání dodavatele, opravdu chcete pokračovat?';
        if (supplierId === null) { throw new Error('supplierId is null'); }
        dispatch(showConfirmDialog({
            title: 'Smazat mléčnici',
            text: isLast
                ? `Opravdu chcete mléčnici smazat? ${lastWarning}`
                : 'Opravdu chcete mléčnici smazat?',
            confirmAction: deleteMilkRoom({supplierId, milkRoomId, isLast}),
        }));
    },
    fillFormFromAres: (ico: string) => dispatch(fillUpdateSupplierFormFromAres(ico)),
});

export default flow([
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
])(Detail) as ComponentClass<OuterProps>;
