import React, {ReactNode} from 'react';
import {Dispatch} from 'redux';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {flow} from 'lodash/fp';

import {StoreState} from 'app/types/StoreState';
import {Components as Layout} from 'layout';

import ResetPasswordForm from '../components/ResetPasswordForm';
import {resetPassword, validateResetPassword} from '../actions';

interface DispatchProps {
    resetPassword(uuid: string): void;
    validateResetPassword(uuid: string): void;
}

interface StateProps {
    isLoading: boolean;
}

type Props = DispatchProps & StateProps & RouteComponentProps<{uuid: string}>;

class ResetPassword extends React.PureComponent<Props> {
    get uuid() {
        const {match: {params: {uuid}}} = this.props;

        return uuid;
    }

    componentDidMount(): void {
        const {validateResetPassword} = this.props;

        validateResetPassword(this.uuid);
    }

    render(): ReactNode {
        const {isLoading} = this.props;

        return (
            <Layout.Page>
                <h1 className="text-center">Nastavte si nové heslo</h1>
                <ResetPasswordForm
                    isLoading={isLoading}
                    onSubmit={this.handleResetPassword}
                />
            </Layout.Page>
        );
    }

    private handleResetPassword = () => {
        resetPassword(this.uuid);
    };
}

const mapStateToProps = (state: StoreState): StateProps => ({
    isLoading: state.layout.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    resetPassword: (uuid: string) => dispatch(resetPassword(uuid)),
    validateResetPassword: (uuid: string) => dispatch(validateResetPassword(uuid)),
});

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(ResetPassword) as typeof ResetPassword;
