import {FormErrors} from 'redux-form';
import {ErrorsFromBE} from 'favorlogic-utils';

import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {MilkRoomValidation} from 'types/model/milkRooms/MilkRoomValidation';
import {SupplierValidation} from 'types/model/suppliers/SupplierValidation';
import {UpdateMilkRoomFormValues} from './types/UpdateMilkRoomFormValues';
import {UpdateSupplierFormValues} from './types/UpdateSupplierFormValues';
import {SuppliersPage} from 'types/model/suppliers/SuppliersPage';
import {SuppliersParams} from 'supplier/types/SuppliersParams';
import {SupplierDetails} from 'types/model/suppliers/SupplierDetails';
import {CreateSupplierFormValues} from './types/CreateSupplierFormValues';

export interface ResetSuppliersAction {
    type: 'supplier/RESET_SUPPLIERS';
}

export const resetSuppliers = (): ResetSuppliersAction => ({
    type: 'supplier/RESET_SUPPLIERS',
});

export interface LoadSuppliersAction {
    type: 'supplier/LOAD_SUPPLIERS';
    payload: SuppliersParams;
}

export const loadSuppliers = (params: SuppliersParams): LoadSuppliersAction => ({
    type: 'supplier/LOAD_SUPPLIERS',
    payload: params,
});

export interface LoadSuppliersSuccessAction {
    payload: SuppliersPage;
    type: 'supplier/LOAD_SUPPLIERS_SUCCESS';
}

export const loadSuppliersSuccess = (payload: SuppliersPage): LoadSuppliersSuccessAction => ({
    type: 'supplier/LOAD_SUPPLIERS_SUCCESS',
    payload,
});

export interface LoadSuppliersErrorAction {
    type: 'supplier/LOAD_SUPPLIERS_ERROR';
    payload: ErrorsFromBE;
}

export const loadSuppliersError = (err: ErrorsFromBE): LoadSuppliersErrorAction => ({
    type: 'supplier/LOAD_SUPPLIERS_ERROR',
    payload: err,
});

export interface SetSuppliersParamsAction {
    payload: SuppliersParams;
    type: 'supplier/SET_SUPPLIERS_PARAMS';
}

export const setSuppliersParams = (params: SuppliersParams): SetSuppliersParamsAction => ({
    type: 'supplier/SET_SUPPLIERS_PARAMS',
    payload: params,
});

// ---

export interface LoadSupplierAction {
    type: 'supplier/LOAD_SUPPLIER';
    payload: number;
}

export const loadSupplier = (payload: number): LoadSupplierAction => ({
    type: 'supplier/LOAD_SUPPLIER',
    payload,
});

export interface LoadSupplierSuccessAction {
    payload: SupplierDetails;
    type: 'supplier/LOAD_SUPPLIER_SUCCESS';
}

export const loadSupplierSuccess = (payload: SupplierDetails): LoadSupplierSuccessAction => ({
    type: 'supplier/LOAD_SUPPLIER_SUCCESS',
    payload,
});

export interface LoadSupplierErrorAction {
    type: 'supplier/LOAD_SUPPLIER_ERROR';
    payload: ErrorsFromBE;
}

export const loadSupplierError = (err: ErrorsFromBE): LoadSupplierErrorAction => ({
    type: 'supplier/LOAD_SUPPLIER_ERROR',
    payload: err,
});

// ---

export interface DeleteSupplierAction {
    type: 'supplier/DELETE_SUPPLIER';
    payload: number;
}

export const deleteSupplier = (payload: number): DeleteSupplierAction => ({
    type: 'supplier/DELETE_SUPPLIER',
    payload,
});

export interface DeleteSupplierSuccessAction {
    type: 'supplier/DELETE_SUPPLIER_SUCCESS';
}

export const deleteSupplierSuccess = (): DeleteSupplierSuccessAction => ({
    type: 'supplier/DELETE_SUPPLIER_SUCCESS',
});

export interface DeleteSupplierErrorAction {
    type: 'supplier/DELETE_SUPPLIER_ERROR';
    payload: ErrorsFromBE;
}

export const deleteSupplierError = (err: ErrorsFromBE): DeleteSupplierErrorAction => ({
    type: 'supplier/DELETE_SUPPLIER_ERROR',
    payload: err,
});

// ---

export interface UpdateSupplierAction {
    type: 'supplier/UPDATE_SUPPLIER';
    payload: number;
}

export const updateSupplier = (payload: number): UpdateSupplierAction => ({
    type: 'supplier/UPDATE_SUPPLIER',
    payload,
});

export interface UpdateSupplierSuccessAction {
    type: 'supplier/UPDATE_SUPPLIER_SUCCESS';
}

export const updateSupplierSuccess = (): UpdateSupplierSuccessAction => ({
    type: 'supplier/UPDATE_SUPPLIER_SUCCESS',
});

export interface UpdateSupplierErrorAction {
    type: 'supplier/UPDATE_SUPPLIER_ERROR';
    payload: ErrorsFromBE;
}

export const updateSupplierError = (err: ErrorsFromBE): UpdateSupplierErrorAction => ({
    type: 'supplier/UPDATE_SUPPLIER_ERROR',
    payload: err,
});

// ---

export interface PrefillUpdateSupplierFormAction {
    type: 'supplier/PREFILL_UPDATE_SUPPLIER_FORM';
    payload: UpdateSupplierFormValues;
}

export const prefillUpdateSupplierForm = (payload: UpdateSupplierFormValues): PrefillUpdateSupplierFormAction => ({
    type: 'supplier/PREFILL_UPDATE_SUPPLIER_FORM',
    payload,
});

// ---

export interface FillUpdateSupplierFormFromAresAction {
    type: 'supplier/FILL_UPDATE_SUPPLIER_FORM_FROM_ARES';
    payload: string;
}

export const fillUpdateSupplierFormFromAres = (ico: string): FillUpdateSupplierFormFromAresAction => ({
    type: 'supplier/FILL_UPDATE_SUPPLIER_FORM_FROM_ARES',
    payload: ico,
});

export interface IsAresLoadingAction {
    type: 'supplier/IS_ARES_LOADING';
    payload: {
        value: boolean,
    };
}

export const isAresLoading = (value: boolean): IsAresLoadingAction => ({
    type: 'supplier/IS_ARES_LOADING',
    payload: {value},
});

// ---

export interface CreateSupplierAction {
    type: 'supplier/CREATE_SUPPLIER';
}

export const createSupplier = (): CreateSupplierAction => ({
    type: 'supplier/CREATE_SUPPLIER',
});

export interface CreateSupplierSuccessAction {
    type: 'supplier/CREATE_SUPPLIER_SUCCESS';
}

export const createSupplierSuccess = (): CreateSupplierSuccessAction => ({
    type: 'supplier/CREATE_SUPPLIER_SUCCESS',
});

export interface CreateSupplierErrorAction {
    type: 'supplier/CREATE_SUPPLIER_ERROR';
    payload: ErrorsFromBE;
}

export const createSupplierError = (err: ErrorsFromBE): CreateSupplierErrorAction => ({
    type: 'supplier/CREATE_SUPPLIER_ERROR',
    payload: err,
});

// ---

export interface DeleteMilkRoomArgs {
    supplierId: number;
    milkRoomId: number;
    isLast: boolean;
}

export interface DeleteMilkRoomAction {
    type: 'supplier/DELETE_MILK_ROOM';
    payload: DeleteMilkRoomArgs;
}

export const deleteMilkRoom = (payload: DeleteMilkRoomArgs): DeleteMilkRoomAction => ({
    type: 'supplier/DELETE_MILK_ROOM',
    payload,
});

export interface DeleteMilkRoomSuccessAction {
    type: 'supplier/DELETE_MILK_ROOM_SUCCESS';
}

export const deleteMilkRoomSuccess = (): DeleteMilkRoomSuccessAction => ({
    type: 'supplier/DELETE_MILK_ROOM_SUCCESS',
});

export interface DeleteMilkRoomErrorAction {
    type: 'supplier/DELETE_MILK_ROOM_ERROR';
    payload: ErrorsFromBE;
}

export const deleteMilkRoomError = (err: ErrorsFromBE): DeleteMilkRoomErrorAction => ({
    type: 'supplier/DELETE_MILK_ROOM_ERROR',
    payload: err,
});

// ---

export interface MilkRoomAddress {
    supplierId: number;
    milkRoomId: number;
}

export interface LoadMilkRoomAction {
    type: 'supplier/LOAD_MILK_ROOM';
    payload: MilkRoomAddress;
}

export const loadMilkRoom = (payload: MilkRoomAddress): LoadMilkRoomAction => ({
    type: 'supplier/LOAD_MILK_ROOM',
    payload,
});

export interface LoadMilkRoomSuccessAction {
    payload: MilkRoom;
    type: 'supplier/LOAD_MILK_ROOM_SUCCESS';
}

export const loadMilkRoomSuccess = (payload: MilkRoom): LoadMilkRoomSuccessAction => ({
    type: 'supplier/LOAD_MILK_ROOM_SUCCESS',
    payload,
});

export interface LoadMilkRoomErrorAction {
    type: 'supplier/LOAD_MILK_ROOM_ERROR';
    payload: ErrorsFromBE;
}

export const loadMilkRoomError = (err: ErrorsFromBE): LoadMilkRoomErrorAction => ({
    type: 'supplier/LOAD_MILK_ROOM_ERROR',
    payload: err,
});

// ---

export interface UpdateMilkRoomAction {
    type: 'supplier/UPDATE_MILK_ROOM';
    payload: MilkRoomAddress;
}

export const updateMilkRoom = (payload: MilkRoomAddress): UpdateMilkRoomAction => ({
    type: 'supplier/UPDATE_MILK_ROOM',
    payload,
});

export interface UpdateMilkRoomSuccessAction {
    type: 'supplier/UPDATE_MILK_ROOM_SUCCESS';
}

export const updateMilkRoomSuccess = (): UpdateMilkRoomSuccessAction => ({
    type: 'supplier/UPDATE_MILK_ROOM_SUCCESS',
});

export interface UpdateMilkRoomErrorAction {
    type: 'supplier/UPDATE_MILK_ROOM_ERROR';
    payload: ErrorsFromBE;
}

export const updateMilkRoomError = (err: ErrorsFromBE): UpdateMilkRoomErrorAction => ({
    type: 'supplier/UPDATE_MILK_ROOM_ERROR',
    payload: err,
});

// ---

export interface PrefillUpdateMilkRoomFormAction {
    type: 'supplier/PREFILL_UPDATE_MILK_ROOM_FORM';
    payload: UpdateMilkRoomFormValues;
}

export const prefillUpdateMilkRoomForm = (payload: UpdateMilkRoomFormValues): PrefillUpdateMilkRoomFormAction => ({
    type: 'supplier/PREFILL_UPDATE_MILK_ROOM_FORM',
    payload,
});

// ---

export interface CreateMilkRoomArguments {
    supplierId: number;
}

export interface CreateMilkRoomAction {
    type: 'supplier/CREATE_MILK_ROOM';
    payload: CreateMilkRoomArguments;
}

export const createMilkRoom = (payload: CreateMilkRoomArguments): CreateMilkRoomAction => ({
    type: 'supplier/CREATE_MILK_ROOM',
    payload,
});

export interface CreateMilkRoomSuccessAction {
    type: 'supplier/CREATE_MILK_ROOM_SUCCESS';
}

export const createMilkRoomSuccess = (): CreateMilkRoomSuccessAction => ({
    type: 'supplier/CREATE_MILK_ROOM_SUCCESS',
});

export interface CreateMilkRoomErrorAction {
    type: 'supplier/CREATE_MILK_ROOM_ERROR';
    payload: ErrorsFromBE;
}

export const createMilkRoomError = (err: ErrorsFromBE): CreateMilkRoomErrorAction => ({
    type: 'supplier/CREATE_MILK_ROOM_ERROR',
    payload: err,
});

// ---

export interface AddMilkRoomInSupplierCreationAction {
    type: 'supplier/ADD_MILK_ROOM_IN_SUPPLIER_CREATION';
}

export const addMilkRoomInSupplierCreation = (): AddMilkRoomInSupplierCreationAction => ({
    type: 'supplier/ADD_MILK_ROOM_IN_SUPPLIER_CREATION',
});

// ---

export interface RemoveMilkRoomInSupplierCreationArguments {
    id: number;
}

export interface RemoveMilkRoomInSupplierCreationAction {
    type: 'supplier/REMOVE_MILK_ROOM_IN_SUPPLIER_CREATION';
    payload: RemoveMilkRoomInSupplierCreationArguments;
}

export const removeMilkRoomInSupplierCreation =
    (payload: RemoveMilkRoomInSupplierCreationArguments): RemoveMilkRoomInSupplierCreationAction => ({
        type: 'supplier/REMOVE_MILK_ROOM_IN_SUPPLIER_CREATION',
        payload,
    });

// ---

export interface ValidateMilkRoomActionArgs {
    values: MilkRoomValidation;
    supplierId: number;

    reject(_: FormErrors): void;
    resolve(_: boolean): void;
}

export interface ValidateMilkRoomAction {
    type: 'supplier/VALIDATE_MILK_ROOM';
    payload: ValidateMilkRoomActionArgs;
}

export const validateMilkRoom = (payload: ValidateMilkRoomActionArgs): ValidateMilkRoomAction => ({
    type: 'supplier/VALIDATE_MILK_ROOM',
    payload,
});

// ---

export interface ValidateSupplierActionArgs {
    values: SupplierValidation;
    isCreate: boolean;

    reject(_: FormErrors): void;

    resolve(_: boolean): void;
}

export interface ValidateSupplierAction {
    type: 'supplier/VALIDATE_SUPPLIER';
    payload: ValidateSupplierActionArgs;
}

export const validateSupplier = (payload: ValidateSupplierActionArgs): ValidateSupplierAction => ({
    type: 'supplier/VALIDATE_SUPPLIER',
    payload,
});

// ---

export interface SetAddingMilkRoomInProgressAction {
    type: 'supplier/SET_ADDING_MILK_ROOM_IN_PROGRESS';
    payload: boolean;
}

export const setAddingMilkRoomInProgress = (payload: boolean): SetAddingMilkRoomInProgressAction => ({
    type: 'supplier/SET_ADDING_MILK_ROOM_IN_PROGRESS',
    payload,
});

// ---

export interface LoadSuppliersMilkRoomsAction {
    type: 'supplier/LOAD_SUPPLIERS_MILK_ROOMS';
    payload: number;
}

export const loadSuppliersMilkRooms = (payload: number): LoadSuppliersMilkRoomsAction => ({
    type: 'supplier/LOAD_SUPPLIERS_MILK_ROOMS',
    payload,
});

export interface LoadSuppliersMilkRoomsSuccessAction {
    payload: MilkRoom[];
    type: 'supplier/LOAD_SUPPLIERS_MILK_ROOMS_SUCCESS';
}

export const loadSuppliersMilkRoomsSuccess =
    (payload: MilkRoom[]): LoadSuppliersMilkRoomsSuccessAction => ({
        type: 'supplier/LOAD_SUPPLIERS_MILK_ROOMS_SUCCESS',
        payload,
    });

export interface LoadSuppliersMilkRoomsErrorAction {
    type: 'supplier/LOAD_SUPPLIERS_MILK_ROOMS_ERROR';
    payload: ErrorsFromBE;
}

export const loadSuppliersMilkRoomsError = (err: ErrorsFromBE): LoadSuppliersMilkRoomsErrorAction => ({
    type: 'supplier/LOAD_SUPPLIERS_MILK_ROOMS_ERROR',
    payload: err,
});

// ---

export interface LoadMilkRoomsAction {
    type: 'supplier/LOAD_MILK_ROOMS';
}

export const loadMilkRooms = (): LoadMilkRoomsAction => ({
    type: 'supplier/LOAD_MILK_ROOMS',
});

export interface LoadMilkRoomsSuccessAction {
    payload: MilkRoom[];
    type: 'supplier/LOAD_MILK_ROOMS_SUCCESS';
}

export const loadMilkRoomsSuccess = (payload: MilkRoom[]): LoadMilkRoomsSuccessAction => ({
    type: 'supplier/LOAD_MILK_ROOMS_SUCCESS',
    payload,
});

export interface LoadMilkRoomsErrorAction {
    type: 'supplier/LOAD_MILK_ROOMS_ERROR';
    payload: ErrorsFromBE;
}

export const loadMilkRoomsError = (err: ErrorsFromBE): LoadMilkRoomsErrorAction => ({
    type: 'supplier/LOAD_MILK_ROOMS_ERROR',
    payload: err,
});

// ---

export interface PrefillCreateSupplierFormAction {
    type: 'supplier/PREFILL_CREATE_SUPPLIER_FORM';
    payload: CreateSupplierFormValues;
}

export const prefillCreateSupplierForm = (payload: CreateSupplierFormValues): PrefillCreateSupplierFormAction => ({
    type: 'supplier/PREFILL_CREATE_SUPPLIER_FORM',
    payload,
});

// ---

export interface FillCreateSupplierFormFromAresAction {
    type: 'supplier/FILL_CREATE_SUPPLIER_FORM_FROM_ARES';
    payload: string;
}

export const fillCreateSupplierFormFromAres = (ico: string): FillCreateSupplierFormFromAresAction => ({
    type: 'supplier/FILL_CREATE_SUPPLIER_FORM_FROM_ARES',
    payload: ico,
});

// ---

export type SupplierAction =
    LoadSuppliersAction
    | LoadSuppliersSuccessAction
    | LoadSuppliersErrorAction
    |
    SetSuppliersParamsAction
    |
    LoadSupplierAction
    | LoadSupplierSuccessAction
    | LoadSupplierErrorAction
    |
    DeleteSupplierAction
    | DeleteSupplierSuccessAction
    | DeleteSupplierErrorAction
    |
    UpdateSupplierAction
    | UpdateSupplierSuccessAction
    | UpdateSupplierErrorAction
    |
    CreateSupplierAction
    | CreateSupplierSuccessAction
    | CreateSupplierErrorAction
    |
    PrefillUpdateSupplierFormAction
    |
    LoadMilkRoomAction
    | LoadMilkRoomSuccessAction
    | LoadMilkRoomErrorAction
    |
    UpdateMilkRoomAction
    | UpdateMilkRoomSuccessAction
    | UpdateMilkRoomErrorAction
    |
    PrefillUpdateMilkRoomFormAction
    |
    CreateMilkRoomAction
    | CreateMilkRoomSuccessAction
    | CreateMilkRoomErrorAction
    |
    AddMilkRoomInSupplierCreationAction
    | RemoveMilkRoomInSupplierCreationAction
    |
    ValidateMilkRoomAction
    |
    ValidateSupplierAction
    |
    SetAddingMilkRoomInProgressAction
    |
    LoadSuppliersMilkRoomsAction
    | LoadSuppliersMilkRoomsSuccessAction
    | LoadSuppliersMilkRoomsErrorAction
    |
    LoadMilkRoomsAction
    | LoadMilkRoomsSuccessAction
    | LoadMilkRoomsErrorAction
    |
    PrefillCreateSupplierFormAction
    | FillCreateSupplierFormFromAresAction
    |
    IsAresLoadingAction
    |
    ResetSuppliersAction
    ;
