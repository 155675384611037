import {flow, includes, map} from 'lodash/fp';
import React, {Component, ReactNode} from 'react';
import {Form} from 'redux-form';
import {reject} from 'favorlogic-utils';

import {Components as Buttons} from 'buttons';
import {Components as Forms, Fields} from 'forms';
import {PropsForForm} from 'forms/components/withForm';
import {Components as Layout} from 'layout';
import {MilkRoom} from 'types/model/milkRooms/MilkRoom';
import {SupplyChainItemFormValues} from '../../types/SupplyChainItemFormValues';
import {SelectOption, SelectOptions} from 'forms/components/BasicSelect';

import {validate} from './validations';

interface OwnProps {
    milkRooms: MilkRoom[] | null;
    milkRoomsInChainIds: number[];
}

type Props = PropsForForm<SupplyChainItemFormValues, OwnProps>;

export const genMilkRoomOptions = (
    milkRooms: MilkRoom[],
    milkRoomsInChainIds: number[],
): SelectOptions<MilkRoom> =>  flow(
    map((x: MilkRoom): SelectOption<MilkRoom> => ({
        label: `${x.name} (${x.veterinaryId})`,
        value: x,
    })),
    reject(x => includes(x.value.id, milkRoomsInChainIds)),
)(milkRooms);

class SupplyChainItemForm extends Component<Props> {
    render(): ReactNode {
        const {
            handleSubmit,
            valid,
            submitting,
            milkRooms,
            milkRoomsInChainIds,
        } = this.props;
        const milkRoomOptions = genMilkRoomOptions(milkRooms || [], milkRoomsInChainIds);

        return (
            <Form
                className="position-relative row"
                onSubmit={handleSubmit}
            >
                <Layout.Loader show={submitting} />

                <div className="col-12">
                    <Fields.Select<MilkRoom>
                        className="mt-2"
                        name="milkRoom"
                        label="Mléčnice*"
                        options={milkRoomOptions}
                    />
                </div>

                <div className="col-12">
                    <div className="d-flex justify-content-end mt-4">
                        <Buttons.Button
                            className="w-100"
                            importance="primary"
                            disabled={!valid || submitting}
                            type="submit"
                        >
                            Přidat
                        </Buttons.Button>
                    </div>
                </div>
            </Form>
        );
    }
}

export default Forms.withForm<SupplyChainItemFormValues, OwnProps, Props>(SupplyChainItemForm, {
    form: 'supplyChainItem',
    validate,
    initialValues: {milkRoom: null},
});
